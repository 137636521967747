@import url("https://fonts.googleapis.com/css2?family=Akshar:wght@300&family=Orbitron:wght@400;500&family=Press+Start+2P&display=swap");

body {
  opacity: 0.84;
  background: url("../../public/assets/background1.png"), lightgray 50%;
  background-color: black;
  background-size: cover;
  background-repeat: repeat-y;
}

h1 {
  color: #fff;
  text-align: center;
  font-family: Orbitron;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 800;
  /* 172.917% */
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
}

.team-h1 {
  margin-top: 16px;
}

h3 {
  font-family: Akshar;
  font-size: 3.0625rem;
  font-style: normal;
  font-weight: 600;
  background: linear-gradient(265deg, #00ffbd -5.84%, #025b8c 111.58%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.team-container {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.allCards {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
}

.heading1 {
  margin-left: 2%;
  color: white;
}
