@import url("https://fonts.googleapis.com/css2?family=Akshar&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body::-webkit-scrollbar {
  width: 0;
}

.homeImage {
  background-color: black;
  
  img {
    width: 100vw;
  }
}

.home_about {
  //height: 200vh;
  width: 100%;
  padding-top: 1.5%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .home_mid {
    display: flex;
    //height: 100vh;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 4rem;

    h3 {
      font-family: Akshar;
      font-size: 5.0625rem;
      background: var(
        --s-gradient-2,
        linear-gradient(260deg, #0047ff -13.37%, #00bcd4 109.53%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      font-family: Roboto;
      line-height: 2.8625rem;
      font-size: 1.9375rem;
      text-align: justify;
    }

    img {
      height: 29.75rem;
      width: 32.25rem;
    }
  }
}


@media (max-width: 768px) {
  .home_about {

    .home_mid {
      padding: 1rem;

      h3 {
        font-size: 1.6rem;
      }

      p {
        font-size: 0.9rem;
        line-height: 1.2rem;
      }
    }
  }
}