.card {
  display: inline-block;
  width: 18%;
  border: 1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin:25px 3.5%; /* Add right margin for spacing between cards */
  border-radius: 1.1875rem;
  border: 2px solid white;
  background: rgba(255, 255, 255, 0.11);
  box-shadow: 18px 28px 20px 1px rgba(0, 0, 0, 0.25);
}

.card-image {
  width: 100%;
  aspect-ratio: 0.85;
  object-fit: cover;
}

.card-details {
  padding: 5%;
  margin-top: -6.2px;
  text-align: center;
  background-color: white;
  fill: #00907F;
  mix-blend-mode: exclusion;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-title {
  font-size: 120%;
  margin-bottom: 3%;
}

.card-designation {
  font-size: 100%;
  color: #000000;
}

.profile-url {
  display: flex;
  width: 35%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  img {
    margin: 7%;
    width: 65%;
  }
}


@media (max-width: 900px) {
  .card {
    width: 21%;
    margin: 25px 6%;
  }

  .card-title {
    font-size: 68%;
  }

  .card-designation {
    font-size: 62%;
  }
}

@media (max-width: 550px) {
  .card {
    width: 30%;
    margin: 25px 10%;
  }

  .card-title {
    font-size: 60%;
  }

  .card-designation {
    font-size: 52%;
  }
}

@media (max-width: 340px) {
  .card {
    width: 80%;
  }

  .card-title {
    font-size: 120%;
  }

  .card-designation {
    font-size: 90%;
  }
}