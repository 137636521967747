@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: black;
}

.content {
  padding-top: 10lvh;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2lvh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 10lvh;
  background-color: black;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  box-shadow: 0px 1px 3px rgba(255, 255, 255, 0.4);
}

.logo {
  margin-top: 3px;
  height: 7vh;
  width: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin-right: 17px;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: grey;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-elements ul a:hover,
.nav-elements ul a.active {
  color: white;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  // background-color: white;
}

.nav-elements ul a.home {
  color: white;
}

.nav-elements ul a.home:hover {
  color: grey;
}

@media (600px <= width <= 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  
  .nav-elements ul li:not(:last-child) {
    margin-right: 0px;
  }

  .menu-icon {
    display: block;
    cursor: cursor;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 10.0lvh;
    background-color: black;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    text-align: center;
  }

  .nav-elements.active {
    width: 100%;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-top: 22px;
  }
}
