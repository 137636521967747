@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&family=Playfair+Display:wght@400;700&display=swap");

.event-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
//   background-color: ;
}

.heading {
//   font-family: "Montserrat", sans-serif;
  font-size: 4rem;
  font-weight: 800;
  text-align: center;
  color: #ffffff;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.event-card {
  background-color: #e7dddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  padding: 2rem;
  overflow: hidden;
  background: linear-gradient(
        180deg,
        rgba(148, 12, 187, 0.24) 0%,
        rgba(85, 7, 108, 0.24) 37.6%,
        rgba(210, 69, 226, 0.24) 73.02%,
        rgba(143, 44, 245, 0.24) 100%
      );
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.event-title {
//   font-family: "Playfair Display", serif;
  font-size: 2.5rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 1rem;
  font-family: Akshar, sans-serif;
        font-size: 3rem;
        font-weight: 600;
        background: linear-gradient(265deg, #00ffbd, #025b8c);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
}

.event-content {
//   font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #d3d3d3;
}

.event-image {
  width: 600px;
  height: 400px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.event-image:hover{
    transform: scale(1.05);
    transition: transform 0.3s;
}

.float-left {
  float: left;
  margin-right: 1.5rem;
}

.float-right {
  float: right;
  margin-left: 1.5rem;
}

@media (max-width: 768px) {
  .heading {
    font-size: 3rem;
  }

  .event-title {
    font-size: 2rem;
  }

  .event-image {
    width: 100%;
    height: auto;
    float: none;
    margin: 0 0 1rem 0;
  }
}
