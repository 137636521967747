@import url("https://fonts.googleapis.com/css2?family=Akshar&family=Orbitron&family=Roboto&display=swap");

.workshop {
  display: flex;
  flex-direction: column;
  padding: 1%;
  align-items: center;
  justify-content: center;

  .heading {
    color: #ffffff;
    text-align: center;
    font-family: Orbitron, sans-serif;
    font-size: 10vh;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 2%;
  }

  .workshop-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 95%;
    padding: 2%;

    .card-container {
      width: 45%;
      margin: 2%;
      text-align: center;
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      background: linear-gradient(
        180deg,
        rgba(148, 12, 187, 0.24) 0%,
        rgba(85, 7, 108, 0.24) 37.6%,
        rgba(210, 69, 226, 0.24) 73.02%,
        rgba(143, 44, 245, 0.24) 100%
      );
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      overflow: hidden;

      .card-heading {
        font-family: Akshar, sans-serif;
        font-size: 3rem;
        font-weight: 600;
        background: linear-gradient(265deg, #00ffbd, #025b8c);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 16px 0;
      }

      .new-image {
        position: relative;
        top: 0.5rem;
        left: 4rem;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
      }

      .Upper-rectangle {
        width: 100%;
        text-align: center;
        position: relative;

        .card-img {
          width: 80%;
          height: auto;
          border-radius: 16px;
          margin-bottom: 16px;
        }

        .Lower-rectangle {
          position: absolute;
          font-family: Roboto, sans-serif;
          bottom: 0;
          width: 100%;
          background: rgba(255, 255, 255, 0.9);
          text-align: center;
          font-size: 1rem;
          opacity: 0;
          transition: opacity 0.5s;
          padding: 8px;
        }

        &:hover .Lower-rectangle {
          opacity: 1;
        }

        .Lower-rectangle a {
          display: inline-block;
          background-color: #025b8c;
          color: #ffffff;
          text-decoration: none;
          font-family: Akshar, sans-serif;
          font-size: 1.2rem;
          font-weight: 600;
          padding: 5px 10px;
          border-radius: 10px;
          transition: background-color 0.3s, transform 0.3s;
          margin-top: 0.8rem;
        }

        .Lower-rectangle a:hover {
          // background-color: #161e22;
          // color: #025b8c;
          transform: scale(1.05);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .heading {
      font-size: 8vh;
    }

    .workshop-container {
      .card-container {
        width: 95%;
        margin: 1%;

        .card-heading {
          font-size: 2rem;
          margin-top: 16px;
        }

        .Upper-rectangle {
          .Lower-rectangle {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
